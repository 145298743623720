

import React from 'react';
import ReactDOM from 'react-dom';

import { getProductsV4, repurchase } from './ajax';
import { toPrettyDollarsRound, getQueryAttribute, toHumanArray } from './helpers';
import LoadingCenter from './LoadingCenter';

import Image from './Image';


{/* <PurchaseCourseList purchasedCourses=[] cart={} /> */}
class PurchaseCourseList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stage: 'loading', // 'loading', 'select'
      products: [], // all individual products
    };
    this.onCartChange = this.onCartChange.bind(this);
  }
  onCartChange(cart) {
    console.log(`[CartPage] Cart change happened.`, { items: cart.items, total: cart.total });
    // the cart changed, force re-render
    this.setState({});
  }
  async componentDidMount() {
    try {
      // warn if cart is unavailable
      if (!this.state.cart) {
        console.error('[PurchaseCourseList] The cart is not loaded into PurchaseCourseList.');
      }
      // load products
      let products = await getProductsV4();
      console.log(`[PurchaseCourseList] Fetched products.`, products);
      this.setState({ stage: 'select', products });

      // subscribe to cart changes
      this.props.cart.subscribe(this.onCartChange);
    }
    catch(e) {
      console.error(`[PurchaseCourseList] Error in componentDidMount()`, e);
    }
  }
  componentWillUnmount() {
    // unsubscribe from cart changes
    this.props.cart.unsubscribe(this.onCartChange);
  }

  render() {
    if (this.state.stage === 'loading') {
      return (
        <div>
          <LoadingCenter />
        </div>
      );
    }

    if (this.state.stage === 'select') {

      // filter courses that are not currently available
      let availableProducts = this.state.products.filter(p => {
        let isAlreadyPurchased = this.props.purchasedCourses.find(pp => pp.id === p.id);
        // return isAlreadyPurchased ? false : true;
        // actually don't filter, let users purchase courses they currently have
        return true;
      });

      console.log('[PurchaseCourseList] These are available products.', availableProducts);

      // return empty component if courses are available for sale
      if (availableProducts.length === 0) {
        return '';
      }

      let availableProductsRender = [];

      for (let product of availableProducts) {
        let isItemInCart = this.props.cart.isItemInCart(product);
        availableProductsRender.push((
          <RepurchaseCourse 
            key={product.id}
            product={product}
            isItemInCart={isItemInCart}
            cart={this.props.cart}
          />
        ));
        console.log('added available product to render', product);
      }

      return (
        <div>
          <h1>Available Courses</h1>
          <p style={{ margin: '20px 0 20px 0' }}>
            Here are some additional courses you can purchase to keep your learning journey fresh!
          </p>
          {availableProductsRender}

          {/* purchase2024 */}
          <p style={{ margin: '20px 0 0 0' }}>
            <small>
              "HOLIDAY" and "LEARN2024" coupon code is valid only for course purchases made between November 11, 2024 and December 31, 2024. Coupon is valid for first-time course enrollment only. Limit one coupon use per customer/account. Cannot be combined with other promotional codes, discounts, or special offers. Not valid on prior purchases. Non-transferable and not redeemable for cash. Must be applied at checkout to receive discount. Course access and materials are subject to our standard <a href="/p/click-through">Terms of Service</a>. Any attempt to share, redistribute, or sell this coupon code is prohibited. We reserve the right to cancel any enrollment or disable course access if the coupon is used fraudulently or in violation of these terms. Not valid for team enrollments or licenses, enterprise licenses or enrollments, or bulk purchases. All course fees remain the responsibility of the student. Course access is for one year from purchase date for live courses, and the future enrollment date for courses bought during a pre-sale.
            </small>
          </p>

        </div>
      );
    }

  }

}

export default PurchaseCourseList;

function RepurchaseCourse(props) {
  let product = props.product;
  let isItemInCart = props.isItemInCart;
  let cart = props.cart;
  return (
    <div className='repurchaseCourseStrip'>
      <div className='courseIcon'>
        {/* <img src={product.checkoutThumbnailLink} alt={product.name} /> */}
        <Image src={product.checkoutThumbnailLink} alt={product.name} width='140' />
      </div>
      <div className='courseContent'>
        <h3>{product.name}</h3>
        <p>{product.description}</p>
        <p><a href={product.checkoutOverviewLink} target='_new'>View Curriculum</a></p>
      </div>
      <div className='coursePrice'>
        <div className='coursePriceAmount'>{toPrettyDollarsRound(product.stripeProduct.price.amount)}</div>

        { isItemInCart && 
          <button className='pi4button pi4activeButton pi2uppercase' onClick={() => cart.remove(product)}>
            <img src='https://pi2-web.s3.amazonaws.com/img/purchase/added@2x.png' />
            Added to cart
          </button>
        }

        {
          !isItemInCart &&
          <button className='pi4button pi2uppercase' onClick={() => cart.add(product)}
          >Add to cart</button>
        }

      </div>
    </div>
  );
}