import React from 'react';

import enums from './enums';
import { postLearnerActivity, getCourse } from './ajax';
import { getNextLessonUrl } from './MenuTools';

import CourseBlockText from './CourseBlockText';
import CourseBlockVideo from './CourseBlockVideo';
import CourseBlockImage from './CourseBlockImage';
import CourseBlockYouTube from './CourseBlockYouTube';
import CourseBlockDocument from './CourseBlockDocument';
import CourseBlockCustomCode from './CourseBlockCustomCode';


const QuizQuestionState = {
  // question has not been 'opened' yet, question icon is 'gray'
  New: 0,
  // question is currently open / in progress, question icon is 'white'
  InProgress: 1,
  // question is currently being 'checked' - submitted by user, don't know if correct/incorrect yet
  Checking: 2,
  // question is already answered correctly
  Correct: 3,
  // question is already answered incorrectly
  Incorrect: 4,
};


// <LearnerQuizPlus lesson={lesson} />
class LearnerQuizPlus extends React.Component {
  constructor(props) {
    super(props);

    // console.log('[LearnerQuizPlus] Running constructor.', { props });

    // set default state metadata to questions
    if (props.lesson.questions) {
      for (let i = 0; i < props.lesson.questions.length; i++) { 
        let question = props.lesson.questions[i];
        if (i === 0) {
          question.state = QuizQuestionState.InProgress;
        }
        else {
          question.state = QuizQuestionState.New;
        }
      }
    }

    this.state = {
      position: 0, // current question index
      questions: props.lesson.questions,
      // TODO store score?
      state: 'new',
      isQuizCompleted: false,

    };

    this.postLessonView = this.postLessonView.bind(this);
    this.onQuestionChange = this.onQuestionChange.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.onContinue = this.onContinue.bind(this);
    this.completeLesson = this.completeLesson.bind(this);
    this.retake = this.retake.bind(this);
  }

  async componentDidMount() {
    try {

      // decide what state we should be in...
      // 'new' - never attempted before, start quiz from scratch
      // 'failed' - quiz failed before, need to redo until you pass teh quiz
      //    - should this exist? hi you are a failure
      //    'you scored 2/4 on your best attempt, you need 70% to complete lesson'
      // 'passed' - quiz passed
      //  - 'you scored 3/4 on your best attempt, you passed the quiz'
      //  - can you retake the quiz although you passed already?

      let lectureProgress = this.props.progress.lessons.find(l => l.lessonId === this.props.lesson.id);
      // console.log('[LearnerQuizPlus] Fetched progress?', { lectureProgress }); 

      // detect if quiz already done
      if (lectureProgress && lectureProgress.isQuiz && lectureProgress.isCompleted === true) {
        this.setState({
          isQuizCompleted: true,
          isQuizPassed: true,
          quizTotal: lectureProgress.quizTotal,
          quizCorrect: lectureProgress.quizCorrect,
          quizCorrectPercent: lectureProgress.quizCorrectPercent,
        });

      } 

      // load course for reasons (so we can 'next lesson')
      let course = await getCourse(this.props.lesson.section.course.id);
      this.setState({ course });

    }
    catch(e) {
      console.error('[LearnerQuizPlus] Error in componentDidMount()', e);
    }
  }

  async postLessonView() {
    try {
      console.log('[LearnerQuizPlus] Posting lesson view...');
      let activity = await postLearnerActivity(
        enums.UserActivity.Type.LessonView, this.props.lesson.id);
    }
    catch(e) {
      console.error('[LearnerQuizPlus] Error in postLessonView()', e);
    }
  }

  onQuestionChange(newQuestionData) {

    let questions = this.state.questions;
    questions[this.state.position] = newQuestionData;

    // console.log('Something happened to question.', { questions });
    this.setState ({ questions });
  }

  onValidate() {

    // console.log('[] Validation question...');

    // fetch 'current' question
    let questions = this.state.questions;
    let question = this.state.questions[this.state.position];
    
    // update question state to 'checking'
    question.state = QuizQuestionState.Checking;
    this.setState( { questions });

    // console.log('[] Question to validate:', question);

    // validate if question answer(s) are correct
    let isCorrect = true;
    for (let i = 0; i < question.answers.length; i++) {
      let answer = question.answers[i];
      if (
        (answer.isSelected && !answer.isCorrect) || // answer selected, but not the correct answer
        (!answer.isSelected && answer.isCorrect) // answer is correct, but not selected
      ) {
        isCorrect = false;
        break;
      }
    }
    // console.log('is answer correct?', isCorrect);

    if (isCorrect) {
      question.state = QuizQuestionState.Correct;
      this.setState( { questions });
    }
    else {
      question.state = QuizQuestionState.Incorrect;
      this.setState( { questions });
    }

  }

  async onContinue() {
    try {
      console.log('[LearnerQuizPlus] Continuing to next question...');

      let isQuizCompleted = this.state.questions.length === this.state.position + 1;

      if (isQuizCompleted) {

        console.log('[LearnerQuizPlus] Quiz is completed.');

        // calculate score
        let quizCorrect = this.state.questions.filter(q => q.state === QuizQuestionState.Correct).length; 
        let quizTotal = this.state.questions.length;
        let quizCorrectPercent = Math.round(quizCorrect / quizTotal * 100);

        let isQuizPassed = false;
        if (quizCorrectPercent >= 70) {
          isQuizPassed = true;
        }

        // submit 'quiz completed'
        await postLearnerActivity(
          enums.UserActivity.Type.QuizComplete,
          this.props.lesson.id,
          quizTotal,
          quizCorrect,
        );

        this.setState({
          isQuizCompleted,
          isQuizPassed,
          quizTotal,
          quizCorrect,
          quizCorrectPercent,
        });

        return;
      }
  
      let questions = this.state.questions;
      let question = this.state.questions[this.state.position + 1];
      question.state = QuizQuestionState.InProgress;
  
      let position = this.state.position + 1;
      this.setState({ questions, position: position });

    }
    catch(e) {
      console.error('[LearnerQuizPlus] Error in onContinue()', e);
    }
  }


  async completeLesson() {
    console.log('[LearnerQuizPlus] Completing lesson...');

    let nextLessonUrl = getNextLessonUrl(this.state.course, this.props.lesson.id);

    // navigate to next lesson
    window.location.replace(nextLessonUrl);
  }

  retake() {
    console.log('[LearnerQuizPlus] Retrying quiz...');


    // set default state metadata to questions
    let questions = this.state.questions;
    for (let i = 0; i < questions.length; i++) { 
      let question = questions[i];
      if (i === 0) {
        question.state = QuizQuestionState.InProgress;
      }
      else {
        question.state = QuizQuestionState.New;
      }
      for (let answer of question.answers) {
        answer.isSelected = false;
      }
    }

    this.setState({
      position: 0,
      questions,
      isQuizCompleted: false,
      isQuizPassed: false,
      quizTotal: null,
      quizCorrect: null, 
      quizCorrectPercent: null,
    });

  }

  render() {

    let lesson = this.props.lesson;

    let blocks = [];


    for (let i = 1; i <= lesson.blocks.length; i++) {
      let block = lesson.blocks[i - 1];

      if (block.type === enums.CourseBlock.Type.Text) {
        blocks.push(
          <CourseBlockText
            id={`block${i}`}
            block={block}
            key={block.id}
          />
        );
      }
      else if (block.type === enums.CourseBlock.Type.Image) {
        blocks.push(
          <CourseBlockImage
            id={`block${i}`}
            block={block}
            alt={lesson.name} // TODO fix for actual alt
            key={block.id}
          />
        );
      }
      else if (block.type === enums.CourseBlock.Type.Video) {
        blocks.push(
          <CourseBlockVideo
            id={`block${i}`}
            block={block}
            key={block.id}
          />
        );
      }
      else if (block.type === enums.CourseBlock.Type.YouTube) {
        blocks.push(
          <CourseBlockYouTube
            id={`block${i}`}
            block={block}
            alt={lesson.name} // TODO fix for actual alt
            key={block.id}
          />
        );
      }
      else if (block.type === enums.CourseBlock.Type.Document) {
        blocks.push(
          <CourseBlockDocument
            id={`block${i}`}
            block={block}
            key={block.id}
          />
        );
      }
      else if (block.type === enums.CourseBlock.Type.CustomCode) {
        blocks.push(
          <CourseBlockCustomCode
            id={`block${i}`}
            block={block}
            key={block.id}
          />
        );
      }
    }

    let progressButtons = [];
    for (let i = 0; i < this.state.questions.length; i++) {
      let q = this.state.questions[i];
      if (q.state === QuizQuestionState.New) {
        progressButtons.push(<IconQuestionNew key={i} />);
      }
      else if (q.state === QuizQuestionState.InProgress ||
               q.state === QuizQuestionState.Checking) {
        progressButtons.push(<IconQuestionInProgress key={i} />);
      }
      else if (q.state === QuizQuestionState.Correct) {
        progressButtons.push(<IconQuestionCorrect key={i} />);
      }
      else if (q.state === QuizQuestionState.Incorrect) {
        progressButtons.push(<IconQuestionIncorrect key={i} />);
      }
    }
    let progressButtonsRender = (
      <div className="pi3quizProgressButtons">
        {progressButtons}
      </div>
    );
    // remove the quiz progress buttons if quiz is already completed
    if (this.state.isQuizCompleted) {
      progressButtonsRender = '';
    }


    let body = '';
    let currentQuestion = this.state.questions[this.state.position];

    if (this.state.isQuizCompleted && this.state.isQuizPassed) {
      body = (
        <div className='pi3quizResult'>
          <br />
          Well done!
          <br />
          You scored {this.state.quizCorrect} / {this.state.quizTotal} ({this.state.quizCorrectPercent}%).
          <br />
          <br />
          <button className='pi3button' onClick={this.completeLesson}>Complete and Continue</button>
        </div>
      );
    }

    else if (this.state.isQuizCompleted && !this.state.isQuizPassed) {
      body = (
        <div className='pi3quizResult'>
          <br />
          Failed :( Try again to pass the quiz and complete the course.
          <br />
          You scored {this.state.quizCorrect} / {this.state.quizTotal} ({this.state.quizCorrectPercent}%).
          <br />
          <br />
          <button className='pi3button' onClick={this.retake}>Retake Quiz</button>
        </div>
      );
    }

    else {
      body = (
        <QuizQuestion
          position={this.state.position}
          question={currentQuestion}
          onChange={this.onQuestionChange}
          onValidate={this.onValidate}
          onContinue={this.onContinue} />
      );
    }


    return (
      <div className="pi3quiz">

        <div className="pi3lessonHeading">
          <h3>
            <div style={{ display: 'inline-block', padding: '3px 10px 0px 0px' }}>
              <div className="icon-lesson-quiz-monochrome"></div>
            </div>
            {this.props.lesson.name}
          </h3>
        </div>

        {blocks}

        {progressButtonsRender}
        {body}
      </div>
    );
  }
}

class QuizQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: null, // selected answer index
    };
  }
  onSelect(position, isSelected) {
    // handle mechanics of selecting answer
    // user can 'select' or 'unselect' a question
    console.log('[LearnerQuiz] we doing teh selection?', { position, isSelected });
    let answers = this.props.question.answers;


    // check if question has multiple answers
    // we could do this from Quiz Question Type too, but why
    let hasMultipleCorrectAnswers = answers.filter(a => a.isCorrect).length > 1 ? true : false;

    console.log('[LearnerQuiz] does this question have multiple correct answers?', hasMultipleCorrectAnswers);

    if (!hasMultipleCorrectAnswers) {
      // only one answer is correct, so un-select anything else
      for (let i = 0; i < answers.length; i++) {
        // set state so that only one new question is selected
        if (i === position) {
          answers[i].isSelected = true;
        }
        else {
          answers[i].isSelected = false;
        }
      }
    }
    else {
      answers[position].isSelected = isSelected;
    }



    this.props.onChange(this.props.question);

  }

  render() {

    let questionBlocksRender = [];
    console.log('question blocks?', this.props.question.questionBlocks);
    for (let block of this.props.question.questionBlocks) {

      if (block.type === enums.CourseBlock.Type.Text) {
        questionBlocksRender.push(
          <CourseBlockText block={block} key={block.id} />
        );
      }

      else if (block.type === enums.CourseBlock.Type.Image) {
        questionBlocksRender.push(
          <CourseBlockImage block={block} key={block.id} />
        );
      }

      else if (block.type === enums.CourseBlock.Type.Video) {
        questionBlocksRender.push(
          <CourseBlockVideo block={block} key={block.id} />
        );
      }

      else if (block.type === enums.CourseBlock.Type.YouTube) {
        questionBlocksRender.push(
          <CourseBlockYouTube block={block} key={block.id} />
        );
      }

      else if (block.type === enums.CourseBlock.Type.Document) {
        questionBlocksRender.push(
          <CourseBlockDocument block={block} key={block.id} />
        );
      }

      else if (block.type === enums.CourseBlock.Type.CustomCode) {
        questionBlocksRender.push(
          <CourseBlockCustomCode block={block} key={block.id} />
        );
      }

    }

    let answers = this.props.question.answers;
    let answersRender = [];

    // question is 'opened', answer can be given/changed by the user
    if (this.props.question.state === QuizQuestionState.InProgress) {
      let isAnswerSelected = false;
      for (let i = 0; i < answers.length; i++) {
        let a = answers[i];
        if (a.isSelected) {
          answersRender.push(
            <div className="pi3quizAnswer active" key={i} onClick={() => this.onSelect(i, false)}>
              {a.answer}
            </div>
          );
          isAnswerSelected = true;
        }
        else {
          answersRender.push(
            <div className="pi3quizAnswer" key={i} onClick={() => this.onSelect(i, true)}>
              {a.answer}
            </div>
          );
        }
      }
      return (
        <div className='pi3quizQuestionSingle'>
          {/* <div className="pi3quizQuestion">
            <h5>
              {this.props.question.question}
            </h5>
          </div> */}
          <div>
            {questionBlocksRender}
          </div>

          <div className="pi3quizAnswers">
            {answersRender}
          </div>
          <div className="pi3quizButtons">
            {
              isAnswerSelected && 
              <button className="pi3quizButton" onClick={() => this.props.onValidate(this.props.question)}>Check</button>
            }
            {
              !isAnswerSelected && 
              <button className="pi3quizButton disabled">Check</button>
            }
          </div>
        </div>
      );
    }

    if (this.props.question.state === QuizQuestionState.Checking) {
      return (
        <div className='pi3quizQuestionSingle'>
          Checking...
        </div>
      );
    }

    if (this.props.question.state === QuizQuestionState.Correct ||
        this.props.question.state === QuizQuestionState.Incorrect) {

      let answersRender = [];
      for (let i = 0; i < answers.length; i++) {
        let a = answers[i];
        // TODO if correct, render 'correct' thing
        // TODO if incorrect, render 'incorrect' thing

        // if question is not selected, and is not correct
        if (!a.isSelected && !a.isCorrect) {
          answersRender.push(
            <div className="pi3quizAnswer disabled" key={i}>
              {a.answer}
            </div>
          );
        }

        else if (a.isCorrect) {
          answersRender.push(
            <div key={i}>
              <div className="pi3quizAnswerLabelCorrect">Correct</div>
              <div className="pi3quizAnswer correct">
                {a.answer}
              </div>
            </div>
          );
        }

        else if (!a.isCorrect) {
          answersRender.push(
            <div key={i}>
              <div className="pi3quizAnswerLabelIncorrect">Incorrect</div>
              <div className="pi3quizAnswer incorrect">
                {a.answer}
              </div>
            </div>
          );
        }

      }

      return (
        <div className='pi3quizQuestionSingle'>
          <div className="pi3quizQuestion">
            <h5>
              {this.props.question.question}
            </h5>
          </div>
          <div className="pi3quizAnswers">
            {answersRender}
          </div>
          <div className="pi3quizButtons">
            <button className="pi3quizButton" onClick={() => this.props.onContinue()}>Continue</button>
          </div>
        </div>
      );


    }





    // console.log('this is my question', this.props.question);



  }
}


export default LearnerQuizPlus;

function IconQuestionNew() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3645_6417)">
        <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#D9D9D9"/>
      </g>
      <defs>
        <clipPath id="clip0_3645_6417">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}

function IconQuestionInProgress() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_3645_6413)">
        <path d="M10 2.4375C11.0109 2.43537 12.0118 2.63732 12.9428 3.03125C13.8424 3.41082 14.6596 3.96154 15.3491 4.65281C16.0395 5.34191 16.5895 6.15847 16.9688 7.05719C17.3627 7.98817 17.5646 8.9891 17.5625 10C17.5646 11.0109 17.3627 12.0118 16.9688 12.9428C16.589 13.8418 16.0383 14.6585 15.3472 15.3475C14.6582 16.0385 13.8417 16.5891 12.9428 16.9688C12.0118 17.3627 11.0109 17.5646 10 17.5625C8.9891 17.5646 7.98818 17.3627 7.05719 16.9688C6.15765 16.5892 5.34042 16.0385 4.65094 15.3472C3.96054 14.6581 3.41047 13.8415 3.03125 12.9428C2.63732 12.0118 2.43537 11.0109 2.4375 10C2.43537 8.9891 2.63732 7.98817 3.03125 7.05719C3.411 6.1582 3.96171 5.34153 4.65281 4.6525C5.3423 3.96123 6.15952 3.41051 7.05906 3.03094C7.98949 2.63736 8.98975 2.43552 10 2.4375ZM10 0C4.47719 0 0 4.47719 0 10C0 15.5228 4.47719 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47719 15.5228 0 10 0Z" fill="#D9D9D9"/>
      </g>
      <defs>
        <clipPath id="clip0_3645_6413">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}

function IconQuestionCorrect() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3645_22708)">
        <path d="M10 0C4.47719 0 0 4.47719 0 10C0 15.5228 4.47719 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47719 15.5228 0 10 0ZM14.7641 6.92594L9.76406 14.4259C9.66404 14.576 9.53197 14.702 9.37736 14.7948C9.22275 14.8877 9.04947 14.945 8.87 14.9628C8.83013 14.9668 8.79007 14.9688 8.75 14.9688C8.58994 14.9688 8.43145 14.9373 8.28357 14.876C8.13569 14.8148 8.00131 14.725 7.88812 14.6119L4.13812 10.8619C4.02494 10.7487 3.93516 10.6143 3.87391 10.4664C3.81265 10.3186 3.78113 10.1601 3.78113 10C3.78113 9.83994 3.81265 9.68144 3.87391 9.53356C3.93516 9.38568 4.02494 9.25131 4.13812 9.13813C4.25131 9.02494 4.38567 8.93516 4.53355 8.87391C4.68143 8.81265 4.83993 8.78112 5 8.78112C5.16007 8.78112 5.31857 8.81265 5.46645 8.87391C5.61433 8.93516 5.74869 9.02494 5.86188 9.13813L8.56062 11.8369L12.7359 5.57406C12.824 5.43921 12.9378 5.32315 13.071 5.23257C13.2041 5.14199 13.3539 5.0787 13.5117 5.04635C13.6694 5.014 13.832 5.01323 13.9901 5.04409C14.1481 5.07495 14.2985 5.13682 14.4325 5.22614C14.5665 5.31546 14.6814 5.43044 14.7707 5.56445C14.86 5.69846 14.9219 5.84884 14.9527 6.0069C14.9835 6.16495 14.9827 6.32755 14.9504 6.48529C14.918 6.64304 14.8547 6.79281 14.7641 6.92594Z" fill="#14B99C"/>
      </g>
      <defs>
        <clipPath id="clip0_3645_22708">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}

function IconQuestionIncorrect() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3645_22711)">
        <path d="M10 0C4.47719 0 0 4.47719 0 10C0 15.5228 4.47719 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47719 15.5228 0 10 0ZM14.6119 12.8881C14.8405 13.1167 14.9689 13.4267 14.9689 13.75C14.9689 14.0733 14.8405 14.3833 14.6119 14.6119C14.3833 14.8405 14.0733 14.9689 13.75 14.9689C13.4267 14.9689 13.1167 14.8405 12.8881 14.6119L10 11.7234L7.11187 14.6119C6.88329 14.8405 6.57327 14.9689 6.25 14.9689C5.92673 14.9689 5.61671 14.8405 5.38813 14.6119C5.15954 14.3833 5.03112 14.0733 5.03112 13.75C5.03112 13.4267 5.15954 13.1167 5.38813 12.8881L8.27656 10L5.38813 7.11187C5.15954 6.88329 5.03112 6.57327 5.03112 6.25C5.03112 5.92673 5.15954 5.61671 5.38813 5.38813C5.61671 5.15954 5.92673 5.03112 6.25 5.03112C6.57327 5.03112 6.88329 5.15954 7.11187 5.38813L10 8.27656L12.8881 5.38813C13.1167 5.15954 13.4267 5.03112 13.75 5.03112C14.0733 5.03112 14.3833 5.15954 14.6119 5.38813C14.8405 5.61671 14.9689 5.92673 14.9689 6.25C14.9689 6.57327 14.8405 6.88329 14.6119 7.11187L11.7234 10L14.6119 12.8881Z" fill="#9A1638"/>
      </g>
      <defs>
        <clipPath id="clip0_3645_22711">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}